/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import Pager from '@core/dev-extreme/utils/pager'
import membershipChargeService from '@/http/requests/membership/charge'

const storeAll = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : null // [{ selector: 'chargeBeginDate', desc: false }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    // pager.setSortOrder(sort)
    // pager.addSortOrder(sort)
    const pageableQuery = pager.toCreatePageable() + pager.toCreateSortable()
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    const response = await membershipChargeService.getByPageableQuery(pageableQuery, filters)
    const data = response.data.body.content
    const totalCount = response.data.body.totalElements ?? 0
    return {
      data: data,
      totalCount: totalCount,
    }
  },
})

const membershipChargeDataSource = new DataSource({
  store: storeAll,
})

export {
  membershipChargeDataSource as MembershipChargeDataSource,
}
