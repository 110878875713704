import axios from '@/libs/axios'
import endpoints from '@/http/endpoints'

export default {
  async getListByFilter(filter) {
    const result = await axios.post(endpoints.membershipCharges.Controller.getListByFilter(), filter)
    return result
  },
  async getByPageableQuery(pageable, filters) {
    const result = await axios.post(endpoints.membershipCharges.Controller.getByPageableQuery(pageable), filters)
    return result
  },
  async findById(id) {
    const result = await axios.post(endpoints.membershipCharges.Controller.findById(id))
    return result
  },
  async create(data) {
    const result = await axios.post(endpoints.membershipCharges.Controller.create(), data)
    return result
  },
  async update(data) {
    const result = await axios.put(endpoints.membershipCharges.Controller.update(), data)
    return result
  },
  async delete(id) {
    const result = await axios.delete(endpoints.membershipCharges.Controller.delete(id))
    return result
  },
  async creditCartChargeAndPaidTest(companyId) {
    const result = await axios.get(endpoints.membershipCharges.Controller.creditCartChargeAndPaidTest(companyId))
    return result
  },
  async getDuePlans() {
    const result = await axios.get(endpoints.membershipCharges.Controller.getDuePlans())
    return result.data.body
  },
  async payDuePlan(planId) {
    const result = await axios.get(endpoints.membershipCharges.Controller.payDuePlan(planId))
    return result
  },
  async payCharges(cardId, data) {
    const result = await axios.post(endpoints.membershipCharges.Controller.payCharges(cardId), data)
    return result
  },
  // #region add
  async addMemberShipCharges(data) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.membershipManagement.Controller.addCharge(), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  // #endregion

  // #region delete
  async deleteMemberShipCharges(data) {
    const promise = new Promise((resolve, reject) => {
      axios.delete(endpoints.membershipManagement.Controller.deleteCharge(data))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
  },
  // #endregion

  // #region edit
  async editMemberShipCharges(data) {
    const promise = new Promise((resolve, reject) => {
      axios.put(endpoints.membershipManagement.Controller.editCharge(data), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
  },
  // #endregion

  // #region get
  async getMemberShipCharges(data) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.membershipManagement.Controller.getCharges(data))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async getMemberShipCharge(data) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.membershipManagement.Controller.getCharge(data))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async getMemberShipChargeUnPaid(data) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.membershipManagement.Controller.getChargesUnpaid(data))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  // #endregion
}
