<template>
  <div class="d-flex flex-column">
    <div id="charges-grid">
      <dx-data-grid
        ref="membershipChargeGridRef"
        :height="hasPermission ? '55vh' : '60vh'"
        :data-source="MembershipChargeDataSource"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :hover-state-enabled="true"
        :row-alternation-enabled="false"
        :customize-columns="customizeColumns"
        @initialized="onInitialized"
        @option-changed="onOptionChanged"
        @content-ready="onContentReady"
        @editor-preparing="onEditorPreparing"
        @toolbar-preparing="hasPermission ? onToolbarPreparing($event) : null"
        >
        <dx-sorting mode="single" />
        <dx-remote-operations
          :paging="true"
          :sorting="true"
          :filtering="true"
        />
        <dx-column
          data-field="membershipPlanName"
          caption="Plan Name"
        />
        <dx-column
          data-field="chargeType"
          caption="Charge Type"
          cell-template="chargeTypeTemplate"
        />
        <template #chargeTypeTemplate="{data}">
          <div :class="resolveChargeType(data.value)" class="badge">
            {{ removeUnderscores(data.value) }}
          </div>
        </template>
        <dx-column
          data-field="chargePeriod"
          caption="Period"
        />
        <dx-column
          :visible="hasPermission"
          data-field="subscriberCompanyName"
          caption="Company Name"
        />
        <!-- <dx-column
          :visible="hasPermission"
          data-field="subscriberCompanyAccountNo"
          caption="Account No"
        /> -->
        <dx-column data-field="excess" />
        <dx-column
          data-field="totalAmount"
          :format="'$#,##0.##'"
        />
        <dx-column
          data-field="refund"
          :format="'$ #,##0.##'"
        />
        <dx-column
          data-field="paidAmount"
          :format="'$ #,##0.##'"
        />
        <dx-column
          data-field="paidAt"
          data-type="datetime"
          :format="'yyyy-MM-dd HH:mm'"
        />
        <dx-column
          data-field="isPaid"
          width="100"
          cell-template="isPaidTemplate"
        />
        <dx-column
          data-field="invoiceId"
          caption="Invoice"
          cell-template="tempInvoice"
        />
        <template #tempInvoice="{data}">
          <router-link
            v-if="data.value"
            :to="{ name: 'apps-invoice-preview', params: { id: data.value } }"
          >
            <span class="text-capitalize d-block badge badge-light-success badge-pill">
              Invoice
            </span>
          </router-link>
        </template>
        <dx-column data-field="rowActions" alignment="center" caption="Actions" width="120px" cell-template="rowActionsTemplate" />
        <template #rowActionsTemplate="{ data }">
          <div class="text-center">
            <dx-util-button :disabled="data.data.isPaid" class="m-haif" icon="bi bi-credit-card-2-back-fill" type="success" hint="Make a payment" @click="openPaymentWidget(data.data)" />
            <dx-util-button v-if="hasPermission" :disabled="data.data.isPaid" class="m-half" icon="icon bi-trash" type="danger" @click="deleteCharge(data.data.id)" />
          </div>
        </template>
        <template #isPaidTemplate="{data}">
          <div>
            <span v-if="data.value" class="badge d-block badge-success">PAID</span>
            <span v-else class="badge badge-danger d-block">UNPAID</span>
          </div>
        </template>
        <!--region    ::DataGird actions -->
        <dx-editing
          refresh-mode="full"
          :allow-deleting="false"
          :use-icons="true"
        />
        <!--endregion ::DataGird actions -->
        <dx-paging :page-size="pagerOptions.pageSize" />
        <dx-pager
          :allowed-page-sizes="pagerOptions.allowedPageSizes"
          :show-info="pagerOptions.showInfo"
          :show-navigation-buttons="pagerOptions.showNavigationButtons"
          :show-page-size-selector="pagerOptions.showPageSizeSelector"
          :visible="pagerOptions.visible"
        />
        <dx-search-panel
          v-if="hasPermission"
          :highlight-search-text="true"
          :visible="true"
          :width="200"
          placeholder="Search..."
        />
        <template #filterToolbar>
          <div class="d-flex align-items-center">
            <dx-util-text-box
              v-model.trim="filters.accountNo"
              :show-clear-button="true"
              mode="text"
              placeholder="Account or Suite No"
              class="mr-1"
              @key-down="onFilterByAccountNo"
              @value-changed="onFilterByAccountNo"
            />
            <dx-util-select-box
              v-model="filters.planId"
              :items="membershipPlans"
              display-expr="text"
              value-expr="value"
              class="mr-half"
              width="200"
              @value-changed="onChangeMembershipPlan"
            />
          </div>
        </template>
      </dx-data-grid>
    </div>
    <div>
      <payment-widget :component-id="paymentWidgetComponentId" :payment-request="paymentRequest" @emit-form-saved="searchByFilter" />
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import membershipChargeService from '@/http/requests/membership/charge'
import membershipPlanService from '@/http/requests/membership/plan'
import useCurrentUser from '@/libs/app/current-user'
import { v4 as uuidv4 } from 'uuid'
// import { Notify } from '@robustshell/utils'
import moment from 'moment'

import { MembershipChargeDataSource } from './membership-charge'

export default {
  name: 'MembershipCharges',
  components: {
    'payment-widget': () => import('./PaymentWidget.vue'),
  },
  setup() {
    const {
      userTenantId,
      userCompanyId,
      userTenantType,
    } = useCurrentUser()
    return {
      userTenantId,
      userCompanyId,
      userTenantType,
    }
  },
  mixins: [GridBase],
  data() {
    return {
      MembershipChargeDataSource,
      icons: ['Edit2Icon', 'TrashIcon'],
      isIndividualTenant: false,
      membershipPlans: [],
      accountNo: null,
      paymentWidgetComponentId: '',
      paymentRequest: {
        chargeIds: [],
        chargeAmount: 0,
      },
      filters: {
        q: '',
        accountNo: null,
        subscriberCompanyId: this.companyId,
        planId: 0,
        isPaid: null,
      },
    }
  },
  computed: {
    dataGrid() {
      return this.$refs.membershipChargeGridRef.instance
    },
    hasPermission() {
      return this.userTenantId === 'PSH' || this.userTenantId === 'DPC'
    },
    companyId() {
      return this.$route.params.companyId
    },
  },
  created() {
    if (this.userTenantType === 'INDIVIDUAL') {
      this.isIndividualTenant = true
    }
  },
  async mounted() {
    if (!this.hasPermission) this.filters.isPaid = null
    this.filters.subscriberCompanyId = this.companyId
    // eslint-disable-next-line eqeqeq
    if (this.userTenantId !== 'PSH' && this.companyId == '0') {
      this.filters.subscriberCompanyId = this.userCompanyId
    }
    MembershipChargeDataSource.searchValue(this.filters)
    MembershipChargeDataSource.load()
    await this.loadMembershipPlans()
  },
  methods: {
    openPaymentWidget(e) {
      const charge = e
      this.paymentRequest.chargeIds.push(charge.id)
      this.paymentRequest.chargeAmount = charge.totalAmount
      this.paymentWidgetComponentId = uuidv4()
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        MembershipChargeDataSource.pageIndex(0)
      }
    },
    async loadMembershipPlans() {
      const result = await membershipPlanService.getListByFilter()
      this.membershipPlansData = result.data.body
      result.data.body.forEach(element => {
        this.membershipPlans.push({ text: element.name, value: element.id, membershipTypeId: element.membershipTypeId })
      })
      this.membershipPlans.unshift({ text: 'All Membership Plans', value: 0 })
      this.selectedMembershipPlan = 0
      if (this.qPlanId) {
        this.selectedMembershipPlan = this.qPlanId
      }
    },
    async deleteCharge(chargeId) {
      this.$swal({
        title: 'Are you sure you want to delete this plan charge?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete the charge',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((async result => {
        if (result.value) {
          const dataSource = this.dataGrid.getDataSource()
          const res = await membershipChargeService.delete(chargeId)
          if (res) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Charge has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            dataSource.reload()
          }
        }
      }))
    },
    onChangeMembershipPlan(e) {
      const selectedPlanId = e.value
      this.filters.planId = selectedPlanId
      MembershipChargeDataSource.searchValue(this.filters)
      MembershipChargeDataSource.reload()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.filters.accountNo = e.event.target.value.toString()
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.filters.accountNo = ''
        this.searchByFilter()
      }
    },
    resolveChargeType(type) {
      if (type === 'NORMAL') return 'badge-light-success'
      if (type === 'EXTRA_USER') return 'badge-light-secondary'
      if (type === 'EXTRA_SHIPMENT_ITEM') return 'badge-light-warning'
      if (type === 'EXTRA_STORE') return 'badge-light-primary'
      return 'badge-light-primary'
    },
    removeUnderscores(str) {
      if (str === 'NORMAL') {
        return 'Membership Subscription Charge'
      }
      if (str === 'EXTRA_USER') {
        return 'Extra User'
      }
      if (str === 'EXTRA_SHIPMENT_ITEM') {
        return 'Extra Shipment Item'
      }
      if (str === 'EXTRA_STORE') {
        return 'Extra Store'
      }
      return str
    },
    formatDateWithMoment(isoString) {
      const date = moment(isoString)
      const formattedDate = date.format('YYYY/MM')
      return formattedDate
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.q = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.q = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.q = ''
            this.searchByFilter()
          }
        }
      }
    },
    searchByFilter() {
      MembershipChargeDataSource.searchValue(this.filters)
      MembershipChargeDataSource.reload()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items

      toolbarItems.push({
        widget: 'dxCheckBox',
        options: {
          text: 'Unpaid',
          value: false,
          onValueChanged: onlyUnpaid => {
            this.filters.isPaid = !onlyUnpaid.value ? null : false
            MembershipChargeDataSource.searchValue(this.filters)
            MembershipChargeDataSource.reload()
          },
        },
        location: 'before',
      })

      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            MembershipChargeDataSource.reload()
          },
        },
        location: 'after',
      })
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
    },
  },
}
</script>

<style lang="scss">

</style>
